import React, { Component } from "react";

export class AppForm extends Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
  }

  onSubmit(e) {
    let { props } = this;
    e.preventDefault();
    let submit = props?.onSubmit || (() => "");
    submit(e, this.form.current.getData(), this.form.current);
  }

  render() {
    let { props } = this;
    return (
      <form ref={this.form} {...props} onSubmit={(e) => this.onSubmit(e)}>
        {props.children}
      </form>
    );
  }
}

export default AppForm;
