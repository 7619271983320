import React, { Component } from "react";
import mixinsFunc from "../mixins";
import { Link } from "react-router-dom";
import EmployeeModal from "./EmployeeModal";

export class EmployeeManagement extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("EmployeeManagement");
    this.mixins = mixinsFunc(this);
    this.state = {
      employeeData: [],
      rowcount: 0,
      search: {
        limit: 10,
        page: 1,
        order_by: "updatedAt",
        order_direction: "DESC",
      },
      editData: {},
      viewMode: false,
      editMode: false,
      popupfields: {},
      popupdata: {},
    };
    this.columns = [
      {
        title: "Employee Name",
        dataIndex: "name",
      },
      {
        title: "Role",
        dataIndex: "role",
      },
      {
        title: "Phone No.",
        dataIndex: "phone_number",
      },
      {
        title: "Location",
        dataIndex: "location",
      },
      {
        title: "Email ID",
        dataIndex: "email_address",
      },
      {
        title: "Password",
        dataIndex: "password",
        render: (text, record) => {
          return (
            <span
              className="text_truncate cursor-pointer"
              title={record?.password}
              onClick={() => alert(record?.password)}
            >
              {record?.password}
            </span>
          );
        },
      },
    ];
    this.modalFields = [
      {
        type: "text",
        name: "name",
        label: "Name",
        required: true,
      },
      {
        type: "select",
        name: "role",
        label: "Role",
        options: _enum.ROLES,
        defaultValue: "LOCATION_MANAGER",
        readOnly: "true",
        required: true,
      },
      {
        type: "number",
        name: "phone_number",
        label: "Phone No.",
        required: true,
      },
      {
        type: "select",
        name: "location",
        label: "Location",
        options: _enum.LOCATIONS,
        required: true,
      },
      {
        type: "text",
        name: "email_address",
        label: "Email ID",
        required: true,
        autoComplete: "none",
      },
      {
        type: "text",
        name: "password",
        label: "Password",
        required: true,
        autoComplete: "none",
      },
    ];
    this.employeeModalRef = React.createRef();
  }

  componentDidMount() {
    this.fetchList();
  }

  fetchList() {
    this.props.api
      .apiGetEmployees(this.state.search)
      .then(({ data, total }) => {
        this.setState({
          employeeData: [...data],
          rowcount: total,
        });
      });
  }

  addModalFields() {
    let fields = (this.modalFields || []).reduce(
      (o, n) => ({ ...o, [n.name]: n }),
      {}
    );
    let formdata = Object.map(fields, (v, k) => "");
    // console.log("formdata: ", fields, formdata);
    this.setState({
      popupfields: fields,
      popupdata: formdata,
    });
  }

  editAction(v) {
    console.log("edit: ", v);
    this.openEmployeeModal();
    this.setState({
      popupdata: Object.except(v, ["createdAt", "updatedAt"]),
      editMode: true,
      viewMode: false,
      editData: v,
    });
  }

  deleteAction(data) {
    console.log("delete action: ", data);
    let payload = {
      location: data?.location,
    };

    confirm(`Are you sure want to delete this item ?`).promise.then(() => {
      this.props.api.apiDeleteEmployee(data, payload).then(() => {
        this.fetchList();
      });
    });
  }

  openEmployeeModal() {
    this.employeeModalRef.current.click();
  }

  closeModal() {
    document.querySelector("#add_employee_modal #bsmodalclose").click();
  }

  handleSubmit(event, data, form) {
    event.preventDefault();
    console.log("formData", data, form);

    const resolveList = () => {
      success(
        `${
          this.state.editMode
            ? "Employee Updated successfully"
            : "Employee added successfully"
        }`
      );
      form.reset();
      this.closeModal();
      this.fetchList();
    };
    if (this.state.editMode) {
      let urldata = {
        location: "BiryaniHub",
      };
      let payload = {
        ...Object.except(data, ["name", "phone_number", "location"]),
      };
      this.props.api.apiUpdateEmployee(payload, urldata).then(resolveList);
    } else {
      let payload = {
        ...data,
        role: "LOCATION_MANAGER",
      };
      this.props.api.apiAddEmployee(payload).then(resolveList);
    }
  }

  updateFields(fields) {
    // fields.find((o) => o.name === "admin_name").options =
    //   this.state.schoolAdmins;

    let namefield = fields.find((o) => o.name === "name");
    let phonefield = fields.find((o) => o.name === "phone_number");
    let locationfield = fields.find((o) => o.name === "location");

    if (this.state.editMode === true) {
      namefield.readOnly = true;
      phonefield.readOnly = true;
      locationfield.readOnly = true;
    } else {
      namefield.readOnly = false;
      phonefield.readOnly = false;
      locationfield.readOnly = false;
    }

    return fields;
  }

  render() {
    let {
      employeeData,
      rowcount,
      editData,
      popupdata,
      popupfields,
      editMode,
      viewMode,
    } = this.state;
    let { columns = [], modalFields } = this;
    modalFields = this.updateFields(modalFields);
    return (
      <AppWrapper title="Employee Management">
        <div className="row mb-3">
          <div className="col"></div>
          <div className="col">
            <Link
              className="btn modal_btn lg menu_management"
              ref={this.employeeModalRef}
              data-bs-toggle="modal"
              data-bs-target="#add_employee_modal"
              onClick={(...args) => this.addModalFields(...args)}
            >
              <i className="fa fa-plus me-2"></i>
              Add Employee
            </Link>
          </div>
        </div>
        <AppTable
          data={employeeData}
          columns={columns}
          reorder={true}
          total={rowcount}
          pageSize={10}
          // editable={false}
          editAction={(data) => this.editAction(data)}
          deleteAction={(data) => this.deleteAction(data)}
        ></AppTable>
        <EmployeeModal
          id="add_employee_modal"
          title={`${editMode ? "Update Employee" : "Add Employee"}`}
          submitText={`${editMode ? "Update" : "Add"}`}
          editdata={editData}
          data={popupdata}
          fields={popupfields}
          // disableallfields={editMode ? true : false}
          disablefields={["role"]}
          onSubmit={(...args) => this.handleSubmit(...args)}
          autoComplete="off"
          onClose={() =>
            this.setState({
              popupdata: {},
              editData: {},
              editMode: false,
              viewMode: false,
            })
          }
        />
      </AppWrapper>
    );
  }
}

export default connect(EmployeeManagement);
