import React, { Component } from "react";

export class PreviewModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabledata: [],
      rowcount: 0,
    };
    this.columns = [
      {
        title: "Category",
        dataIndex: "category",
      },
      {
        title: "Item Name",
        dataIndex: "item_name",
      },
      {
        title: "Tray Size",
        dataIndex: "tray_size",
      },
      {
        title: "Quantity",
        dataIndex: "quantity",
      },
      {
        title: "Price Per Tray",
        dataIndex: "price_per_tray",
        render: (text) => <>${text}</>,
      },
      {
        title: "Spice Level",
        dataIndex: "spice_level",
      },
      {
        title: "Amount",
        dataIndex: "price",
        render: (text) => <>${text}</>,
      },
    ];
  }
  componentDidMount() {
    window.PreviewModal = this;
    if (this.props.data) {
      this.setState({
        tabledata: this.props.data?.items,
      });
    }
  }
  render() {
    let { props, submitText = "Submit", columns } = this;
    let { tabledata, rowcount } = this.state;
    let { id, title, onClose, onSubmit, data = [] } = props;
    let attrs = Object.except(props, ["id", "title", "type", "onClose"]);
    let {
      name,
      phone_number,
      address,
      date,
      time,
      weekday,
      delivery_mode,
      total,
      discount_percent,
      grand_total,
    } = data;
    return (
      <AppModalBs
        id={id}
        title={title}
        onClose={onClose}
        modalDialogClass="modal-xl"
        className="previewModal"
      >
        {/* <AppFormData {...attrs} onClose={onClose}></AppFormData> */}
        <div className="top_box mb-4">
          <p className="row_heading">Customer Information:</p>
          <div className="box">
            <p>
              <span>Customer Name :</span>
              <span>{name || "-"}</span>
            </p>
            <p>
              <span>Customer Mobile No. :</span>
              <span>{phone_number || "-"}</span>
            </p>
            <p>
              <span>Address :</span>
              <span>{address || "-"}</span>
            </p>
          </div>
        </div>
        <div className="top_box mb-4">
          <p className="row_heading">Event Information:</p>
          <div className="box">
            <p>
              <span>Event Date :</span>
              <span>{date || "-"}</span>
            </p>
            <p>
              <span>Event Time :</span>
              <span>{time || "-"}</span>
            </p>
            <p>
              <span>Weekday :</span>
              <span>{weekday || "-"}</span>
            </p>
            <p>
              <span>Pickup / Delivery :</span>
              <span>{delivery_mode || "-"}</span>
            </p>
          </div>
        </div>
        <div className="top_box mb-4">
          <p className="row_heading">Menu Details:</p>
          <AppTable
            data={data?.items}
            columns={columns}
            reorder={true}
            total={rowcount}
            pageSize={10}
            showPagination={false}
            editable={false}
            deletable={false}
            viewable={false}
            className="previewmodal_ant_table"
          ></AppTable>
        </div>
        <div className="bottom_box">
          <p>Total</p>
          <p className="value">{total ? `$${total}` : "-"}</p>
        </div>
        <div className="bottom_box">
          <p>Discount</p>
          <p className="value">
            {discount_percent ? `${discount_percent}%` : "-"}
          </p>
        </div>
        <hr />
        <div className="bottom_box">
          <p>Grand Total</p>
          <p className="value">{grand_total ? `$${grand_total}` : "-"}</p>
        </div>
        <Row className="mt-4">
          <Col
            className={Object.className({
              "text-right": true,
            })}
          >
            <button
              type="submit"
              className="btn modal_btn p-0"
              onClick={onSubmit}
            >
              {submitText}
            </button>
          </Col>
          {/* <Col>
            <button
              type="reset"
              className={Object.className({
                "btn cancel-btn": true,
              })}
              data-bs-dismiss="modal"
              onClick={onClose}
            >
              Cancel
            </button>
          </Col> */}
        </Row>
      </AppModalBs>
    );
  }
}

export default PreviewModal;
