import React, { Component } from "react";
import { Link } from "react-router-dom";
import PreviewModal from "../PreviewModal";
import "../party_orders.scss";
export class Configurations extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("Configurations");
    this.state = {
      form: {},
      items: [{}],
      menuData: [],
      selectedMenuLists: [],
      selectedCategory: "",
      grandValue: 0,
      rowcount: 0,
      search: {
        // limit: 10,
        // page: 1,
        order_by: "updatedAt",
        order_direction: "DESC",
      },
      popupdata: {},
      partyWeekDay: "",
    };
    this.previewModalRef = React.createRef();
  }

  get formEle() {
    return this.refs?.form?.form?.current;
  }

  get formData() {
    let formData = this.formEle?.getData() || {};
    let parsedData = parseInput(formData);
    parsedData.items = (parsedData.items || []).map((field) => {
      return {
        ...field,
      };
    });

    return parsedData;
  }

  componentDidMount() {
    this.getMenuLists();
  }

  getMenuLists() {
    let payload = {
      location: "BiryaniHub",
    };
    this.props.api
      .apiGetMenuItems(this.state.search, payload)
      .then(({ data, total }) => {
        console.log({ apiGetMenuItems: data });
        this.setState({
          menuData: data,
          rowcount: total,
        });
      });
  }

  addField(e) {
    let { items } = this.state;
    console.log({ items });
    items = [...items, {}];
    this.setState({
      items,
    });
  }

  deleteField(index) {
    const removeLocal = () => {
      let { items } = this.state;
      items = items.filter((v, i) => i != index);
      this.setState({
        items,
      });
    };
    removeLocal();
  }

  openPreviewModal() {
    this.previewModalRef.current.click();
  }

  closeModal() {
    document.querySelector("#preview_modal #bsmodalclose").click();
  }

  handleTypes(field) {
    // console.log({ handleTypes: field.key });
    let MENU_OPTIONS;

    // console.log(menucategories[field.key]);
    let pricelist = [];
    let itemNamesArr = this.state.menuData
      ?.filter((item) => {
        if (item.item_category === field.key) {
          let targetObj = item.item_category === field.key;
          pricelist.push(item);
          return targetObj && targetObj;
        }
      })
      .map((item, i) => item?.item_name);
    // console.log({ itemNamesArr });
    // console.log({ pricelist });
    // this.setState({
    //   selectedMenuLists: pricelist,
    // });
    let lowerItems = itemNamesArr?.map((element) => {
      return element.toLowerCase();
    });
    // console.log({ lowerItems });
    MENU_OPTIONS = lowerItems.options();

    return {
      MENU_OPTIONS,
      pricelist,
    };
  }

  handleCategoryTypeChange(v, i) {
    console.log({ handleCategoryTypeChange: v[0].key, i });
    // name={`items.${i}.price`}

    const setFields = (state) => {
      let { isSelect, MENU_OPTIONS, pricelist } = this.handleTypes(v[0]);
      let items = Object.setNested(state.items, `${i}.hasSelect`, isSelect);
      items = Object.setNested(items, `${i}.MENU_OPTIONS`, MENU_OPTIONS);
      items = Object.setNested(items, `${i}.withprice`, pricelist);
      items = Object.setNested(items, `${i}.selectedprice`, 0);
      items = Object.setNested(items, `${i}.quantity`, 0);
      console.log({ items, isSelect, MENU_OPTIONS });

      // let input = document.querySelector(`[name="items.${i}.price"]`);
      // console.log(input.value);
      // input.value = 0;

      return items;
    };
    this.setState((state) => ({
      items: setFields(state),
      selectedCategory: v[0].key,
    }));
  }

  handleMenuItemChange(v, i) {
    console.log({ handleMenuItemChange: v[0].key, i });
    console.log(this.state.items[0].withprice, v[0].key);
    let targetfield = this.state.menuData.filter((item, i) => {
      if (item.item_name.toLowerCase() === v[0].key) {
        return item;
      }
    });
    console.log({ targetfield });
    let qtyinput = document.querySelector(`[name="items.${i}.quantity"]`);
    // console.log(qtyinput);
    qtyinput.value = "1";

    const setFields = (state) => {
      let { isSelect, pricelist, MENU_OPTIONS } = this.handleTypes({
        key: this.state.selectedCategory,
        value: this.state.selectedCategory,
      });
      console.log({ pricelist });
      let items = Object.setNested(state.items, `${i}.hasSelect`, isSelect);
      items = Object.setNested(items, `${i}.MENU_OPTIONS`, MENU_OPTIONS);
      items = Object.setNested(items, `${i}.withprice`, pricelist);
      items = Object.setNested(items, `${i}.selectedprice`, 0);
      items = Object.setNested(items, `${i}.quantity`, 1);
      items = Object.setNested(
        items,
        `${i}.selectedprice`,
        targetfield[0]?.item_retail_price
      );
      return items;
    };

    this.setState((state) => ({
      items: setFields(state),
    }));
  }

  handleQuantityChange(e, i) {
    // console.log({ handleMenuItemChange: e.target.value, i });
    let qty = e.target.value;
    if (qty === "") {
      qty = 0;
    } else {
      qty = JSON.parse(qty);
    }

    const setFields = (state) => {
      // console.log({ setFields: state.items[i] });
      let { isSelect, pricelist } = this.handleTypes({
        key: this.state.selectedCategory,
        value: this.state.selectedCategory,
      });
      // let items = Object.setNested(state.items, `${i}.hasSelect`, isSelect);
      // items = Object.setNested(state.items, `${i}.withprice`, pricelist);
      let items = Object.setNested(state.items, `${i}.quantity`, qty);
      // console.log({ items });
      return items;
    };

    this.setState((state) => ({
      items: setFields(state),
    }));
  }

  handleDiscountCalc(e) {
    // console.log({ handleDiscountCalc: e.target.value });
    let discountValue = e.target.value;
    let finalTotalInput = document.querySelector("#final-total-input");
    let grandTotalInput = document.querySelector("#grand-total-input");
    // console.log(finalTotalInput.value);
    // const percentage = (discountValue / 100) * finalTotalInput.value;
    // console.log({ grandTotal: percentage });

    // Original price
    let originalPrice = finalTotalInput.value;

    // Discount percentage
    let discountPercentage = discountValue;

    // Calculate the discount amount
    let discountAmount = (discountPercentage / 100) * originalPrice;
    // console.log({ discountAmount });
    // Calculate the final price after applying the discount
    let finalPrice = originalPrice - discountAmount;
    console.log({ finalPrice });

    if (grandTotalInput) {
      grandTotalInput.value = finalPrice.toFixed(2);
    }
  }

  handleDeliveryCharge(e) {
    // console.log({ handleDeliveryCharge: e.target.value });
    let grandTotalInput = document.querySelector("#grand-total-input");
    let grandTotalInputValue = grandTotalInput.value;

    let deliveryCharge = e.target.value;
    deliveryCharge = deliveryCharge.parse();

    console.log(deliveryCharge, " + ", Number(grandTotalInputValue));

    let priceWithDeliverCharge = deliveryCharge + Number(grandTotalInputValue);

    console.log({ priceWithDeliverCharge });

    this.mixin.debounce(() => {
      if (deliveryCharge === 0) {
        grandTotalInput.value = deliveryCharge;
      } else {
        grandTotalInput.value = priceWithDeliverCharge;
      }
    }, 2000);
  }
  // ----------------------------------------------------------------

  calculateGrandTotal() {
    // Get user inputs
    let totalInput = document.querySelector("#final-total-input");
    let discountInput = document.querySelector("#discount-input");
    let deliveryInput = document.querySelector("#delivery-charge-input");
    let grandTotalInput = document.querySelector("#grand-total-input");

    let total = parseFloat(totalInput.value) || 0;
    let discountPercentage = parseFloat(discountInput.value) || 0;
    let deliveryCharge = parseFloat(deliveryInput.value) || 0;

    // Calculate discount
    let discount = (total * discountPercentage) / 100;

    // Calculate grand total
    let grandTotal = total - discount + deliveryCharge;

    // Display results
    let Total = total.toFixed(2);
    let Discount = discount.toFixed(2);
    let DeliveryCharge = deliveryCharge.toFixed(2);
    let GrandTotal = grandTotal.toFixed(2);
    grandTotalInput.value = GrandTotal;
    console.log({ Total, Discount, DeliveryCharge, GrandTotal });
  }

  // ----------------------------------------------------------------
  handleDateChange(value) {
    if (value) {
      let weekday = new Date(value).getDayFullName();
      console.log({ weekday });
      this.setState({
        partyWeekDay: weekday,
      });
    }
  }

  getTotalPrice() {
    let arrs = this.state.items;
    // console.log({ arrs });
    let totalPrice;
    arrs = arrs.filter((obj) => Object.keys(obj).length > 0);

    totalPrice = arrs.reduce((sum, obj) => {
      if (obj.selectedprice !== NaN) {
        // console.log("reduce calc: ", sum + obj.selectedprice * obj.quantity);
        return sum + obj.selectedprice * obj.quantity;
      }
    }, 0);
    let finalTotalInput = document.querySelector("#final-total-input");
    if (finalTotalInput) {
      // console.log(finalTotalInput);
      // console.log({ totalPrice });
      finalTotalInput.value = totalPrice.toFixed(2);
      // this.setState({
      //   grandValue: priceWithDeliverCharge,
      // });
    }
  }

  sumitForm(e, data, form) {
    let parsedPayload = parseInput(data);
    console.log({ parsedPayload, data });
    this.setState({
      popupdata: parsedPayload,
    });
    this.openPreviewModal();
  }

  handlePreviewSubmit() {
    let payload = {
      ...this.state.popupdata,
    };
    // console.log({ payload });
    this.api.apiAddPartyOrders(payload).then((res) => {
      if (res) {
        console.log({ apiAddPartyOrders: res });
        success("Ordered Successfully");
        this.closeModal();
        this.navigate("/admin/party_orders");
      }
    });
  }

  render() {
    let { items, grandValue, popupdata } = this.state;
    this.getTotalPrice();
    return (
      <AppWrapper title="Party Orders">
        <div className="col-12 col-md-6 d-flex align-items-center justify-content-md-end d-none">
          <Link
            className="btn modal_btn lg"
            ref={this.previewModalRef}
            data-bs-toggle="modal"
            data-bs-target="#preview_modal"
            // onClick={(...args) => this.addModalFields(...args)}
          >
            <i className="fa fa-plus me-2"></i>
            Add Item
          </Link>
        </div>
        <AppForm ref="form" onSubmit={(...args) => this.sumitForm(...args)}>
          <p className="row_heading">Customer Information</p>
          <div className="row mb-4">
            <div className="col-12 col-md-3">
              <AppInput
                type="text"
                label="Customer Name"
                name="name"
                required={true}
                maxLength="50"
                pattern="^[A-Za-z][a-zA-Z0-9\/ \\ ]*$"
                invalidmessage="Value Should be Valid Aphanumeric Content"
                placeholder="Enter Name"
              ></AppInput>
            </div>
            <div className="col-12 col-md-3">
              <AppInput
                type="text"
                label="Mobile Number"
                name="phone_number"
                pattern="[0-9]{10}"
                placeholder="Enter Mobile No."
              ></AppInput>
            </div>
            <div className="col-12 col-md-3">
              <AppInput
                type="text"
                label="Address"
                name="address"
                placeholder="Enter Address"
              ></AppInput>
            </div>
          </div>
          <p className="row_heading">Event Information</p>
          <div className="row mb-4">
            <div className="col-12 col-md-3">
              <AppInput
                type="date"
                label="Date"
                name="date"
                onChange={(...args) => this.handleDateChange(...args)}
                placeholder="Select Date"
              ></AppInput>
            </div>
            <div className="col-12 col-md-3">
              <AppInput
                type="time"
                label="Time"
                name="time"
                placeholder="Select Time"
              ></AppInput>
            </div>
            <div className="col-12 col-md-3">
              <AppInput
                type="text"
                label="Weekday"
                name="weekday"
                defaultValue={this.state.partyWeekDay}
                readOnly={true}
              ></AppInput>
            </div>
            <div className="col-12 col-md-3">
              <AppInput
                type="select"
                label="Pickup / Delivery"
                name="delivery_mode"
                options={_enum.DELIVERY_MODE}
                placeholder="Select One"
              ></AppInput>
            </div>
          </div>
          <p className="row_heading">Menu Details</p>
          {items.map((field, i) => (
            <div className="row position-relative" key={i}>
              <div className="col-12 col-md-3">
                <AppInput
                  type="select"
                  label="Category"
                  name={`items.${i}.category`}
                  options={Object.keys(menucategories).options()}
                  // options={_enum.PARTY_ORDER_CATEGORIES}
                  onChange={(v) => this.handleCategoryTypeChange(v, i)}
                  placeholder="Select Category"
                  required={true}
                ></AppInput>
              </div>
              <div className="col-12 col-md-3">
                <AppInput
                  type="select"
                  label="Item Name"
                  name={`items.${i}.item_name`}
                  options={items[i].MENU_OPTIONS}
                  onChange={(v) => this.handleMenuItemChange(v, i)}
                  placeholder="Select Item"
                  required={true}
                ></AppInput>
              </div>
              <div className="col-12 col-md-3">
                <AppInput
                  type="select"
                  label="Tray Size"
                  name={`items.${i}.tray_size`}
                  options={_enum.TRAY_SIZE}
                  placeholder="Select Size"
                ></AppInput>
              </div>
              <div className="col-12 col-md-3">
                <AppInput
                  type="number"
                  label="Price Per Tray"
                  name={`items.${i}.price_per_tray`}
                  placeholder="Enter Price in $"
                  min="0"
                ></AppInput>
              </div>
              <div className="col-12 col-md-3">
                <AppInput
                  type="number"
                  label="Quantity"
                  name={`items.${i}.quantity`}
                  placeholder="Enter quantity"
                  onChange={(v) => this.handleQuantityChange(v, i)}
                  min="0"
                  required={true}
                ></AppInput>
              </div>
              <div className="col-12 col-md-3">
                <AppInput
                  type="select"
                  label="Spice Level"
                  name={`items.${i}.spice_level`}
                  options={_enum.SPICE_LEVEL}
                  placeholder="Select any"
                  required={true}
                ></AppInput>
              </div>
              <div className="col-12 col-md-3">
                <AppInput
                  type="number"
                  label="Price"
                  name={`items.${i}.price`}
                  defaultValue={items[i].selectedprice}
                  readOnly={true}
                  controlClass="price-input"
                  min="0"
                ></AppInput>
              </div>
              {i != 0 && (
                <button
                  onClick={() => this.deleteField(i)}
                  className="btn delete_btn"
                >
                  <i className="fa fa-trash-o text-error" />
                </button>
              )}
            </div>
          ))}
          <div className="row mb-4">
            <div className="col-12">
              <button
                className="btn bg-transparent flex gap-2 color-red"
                type="button"
                onClick={(e) => this.addField(e)}
              >
                <i className="fa fa-plus m-auto" />
                <span className="">Add more items</span>
              </button>
            </div>
          </div>
          {/* BOTTOM CALCULATION BOX */}
          <div className="row">
            <div className="d-flex align-items-center justify-content-between w-100 calculation_input_outer">
              <p className="row_heading">Total</p>
              <AppInput
                type="number"
                label=""
                name="total"
                id="final-total-input"
                onChange={(v) => this.calculateGrandTotal(v)}
                readOnly={true}
                showdollaricon="true"
                controlClass="border-0"
                defaultValue="0"
                min="0"
              ></AppInput>
            </div>
            <div className="d-flex align-items-center justify-content-between w-100 calculation_input_outer">
              <p className="row_heading">Discount</p>
              <AppInput
                type="number"
                name="discount_percent"
                id="discount-input"
                className="discount_input"
                showpercenticon="true"
                // onChange={(v) => this.handleDiscountCalc(v)}
                onChange={(v) => this.calculateGrandTotal(v)}
                min="0"
              ></AppInput>
            </div>
            <div className="d-flex align-items-center justify-content-between w-100 calculation_input_outer">
              <p className="row_heading">Delivery</p>
              <AppInput
                type="number"
                name="delivery_charge"
                id="delivery-charge-input"
                showdollaricon="true"
                // onChange={(v) => this.handleDeliveryCharge(v)}
                onChange={(v) => this.calculateGrandTotal(v)}
                min="0"
              ></AppInput>
            </div>
            <hr />
            <div className="d-flex align-items-center justify-content-between w-100 calculation_input_outer">
              <p className="row_heading">Grand Total</p>
              <AppInput
                type="number"
                name="grand_total"
                id="grand-total-input"
                placeholder=""
                showdollaricon="true"
                controlClass="border-0"
                // defaultValue={grandValue}
                readOnly={true}
              ></AppInput>
            </div>
          </div>
          <div className="flex mt-4">
            <div className="grow"></div>
            <Link
              to={"/admin/party_orders"}
              className="btn cancel-btn ms-2 me-2"
            >
              Cancel
            </Link>
            <button className="btn modal_btn lg">Save & Preview</button>
          </div>
        </AppForm>
        <PreviewModal
          id="preview_modal"
          title="Preview"
          submitText="Submit"
          data={popupdata}
          onSubmit={(...args) => this.handlePreviewSubmit(...args)}
          onClose={() =>
            this.setState({
              popupdata: {},
            })
          }
        />
      </AppWrapper>
    );
  }
}

export default connect(Configurations);
