import React, { Component } from "react";

class AppModal extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("appModal");
    this.state = {
      open: props.active,
    };
  }

  componentDidMount() {}

  componentDidUpdate(props) {
    this.state.open !== props.active && this.setState({ open: props.active });
  }

  closeModel() {
    let promise = window.modalrequest[this.props.id];
    if (promise?.reject) {
      promise.reject();
    }
    this.setState({ open: false });
    this.props.onCancel && this.props.onCancel();
  }

  submitModel(e) {
    e.preventDefault();
    let promise = window.modalrequest[this.props.id];
    if (promise?.resolve) {
      promise.resolve();
    }
    setTimeout(() => {
      this.setState({ open: false });
      this.props.onClose && this.props.onClose();
    }, 0);
  }

  render() {
    return (
      <div>
        {this.state.open ? (
          <>
            <div className="tailwind AppModal justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[1057] outline-none focus:outline-none custom_app_modal">
              <form
                className="relative w-auto my-6 mx-auto max-w-3xl"
                onSubmit={(e) => this.submitModel(e)}
                id="appmodal"
              >
                {/*content*/}
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none modal_box">
                  <div className="modal_header text-end">
                    <button
                      type="button"
                      className="modal_close_btn"
                      onClick={() => this.closeModel(false)}
                    >
                      x
                    </button>
                  </div>
                  {/*header*/}
                  {this.props.title && (
                    <div className="flex items-start justify-between !p-4 rounded-t">
                      <h4 className="text-2xl font-semibold mb-0 text-slate-600 modal_title">
                        {this.props.title}
                      </h4>
                    </div>
                  )}
                  {/*body*/}
                  <div className="relative p-6 flex-auto">
                    <div className="text-slate-500 text-lg leading-relaxed">
                      {this.props.children}
                    </div>
                  </div>
                  {/*footer*/}
                  {this.props?.footer && (
                    <div className="flex items-center justify-center p-2 border-t border-solid border-slate-200 rounded-b modal_footer">
                      <button className="btn modal_btn modal_active_btn">
                        {this.props?.okText || "Submit"}
                      </button>
                      {["confirm"].includes(this.props?.type) && (
                        <button
                          className="btn cancel-btn modal_cancel_btn mx-2"
                          type="button"
                          onClick={() => this.closeModel(false)}
                        >
                          {this.props?.cancelText || "Close"}
                        </button>
                      )}
                    </div>
                  )}
                </div>
              </form>
            </div>
            <div className="opacity-50 fixed inset-0 z-[1056] bg-black"></div>
          </>
        ) : null}
      </div>
    );
  }
}
export default connect(AppModal);
