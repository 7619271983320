export default {

    apiGetMenuItems(data, urldata = {}) {
        urldata = { ...mixins.params(), ...urldata };
        data = {
            ...Object.except(data, ['location'])
        }
        console.log({ apiGetMenuItems: data, urldata });
        return this.request(this.api().getMenuItems, data)
            .urltransform(urldata)
            .exec()
            .log()
            .mapresults((response) => {
                return {
                    data: response.data[0].records,
                    total: response.data[0].pagination[0]?.totalRecords || 0
                }
            })
            .get();
    },

    apiAddMenuItem(data) {
        return this.request(this.api().addMenuItem, data)
            .exec().log()
            .mapresults((response) => {
                console.log({ apiAddMenuItem: response });
                return response.data
            })
            .get();
    },

    apiUpdateMenuItem(data) {
        console.log({ apiUpdateMenuItem: data });

        let payload = {
            item_name: data?.item_name,
            item_image: data?.item_image,
            item_retail_price: data?.item_retail_price,
            location: data?.location,
            item_order: data?.item_order,
            attachment_id: data?.attachment_id,
        }
        return this.request(this.api().updateMenuItem, payload)
            // .urltransform(urldata)
            .exec().log().get();
    },

    apiDeleteMenuItem(data) {

        return this.request(this.api().deleteMenuItem, data)
            // .urltransform(urldata)
            .exec().log().get();
    },

    apiAddMenuFile(data) {
        return this.request(this.api().addMenuFile, data)
            .exec().log()
            .get();
    },

    apiGetEmployees(data, urldata = {}) {
        urldata = { ...mixins.params(), ...urldata };
        data = {
            ...Object.except(data, ['location'])
        }
        console.log({ apiGetEmployees: data, urldata });
        return this.request(this.api().getEmployees, data)
            .urltransform(urldata)
            .exec()
            .log()
            .mapresults((response) => {
                return {
                    data: response.data[0].records,
                    total: response.data[0].pagination[0]?.totalRecords || 0
                }
            })
            .get();
    },

    apiAddEmployee(data) {
        return this.request(this.api().addEmployee, data)
            .exec().log()
            .mapresults((response) => {
                console.log({ apiAddEmployee: response });
                return response.data
            })
            .get();
    },

    apiUpdateEmployee(data, urldata = {}) {
        urldata = { ...mixins.params(), ...urldata };
        console.log({ apiUpdateEmployee: data });

        let payload = {
            ...data
        }
        return this.request(this.api().editEmployee, payload)
            .urltransform(urldata)
            .exec().log().get();
    },

    apiDeleteEmployee(data, urldata = {}) {
        urldata = { ...mixins.params(), ...urldata };
        return this.request(this.api().deleteEmployee, data)
            .urltransform(urldata)
            .exec().log().get();
    },

    // FILE UPLOAD

    apiFileUploader(data, urldata = mixins.params()) {
        return this.request(this.api().file_upload, data)
            .urltransform(urldata)
            .multipart()
            .exec()
            .log()
            .get();
    },

    apiImageFileUploader(data) {
        return this.request(this.api().image_file_upload, data)
            .multipart()
            .exec()
            .log()
            .get();
    },

    apiGeneratePdf(data) {
        return this.request(this.api().generate_party_pdf, data)
            .exec()
            .log()
            .get();
    },

}