import React, { Component } from "react";

export class AppDatePicker extends Component {
  constructor(props) {
    super(props);
    this.appinput = React.createRef();
    this.state = {
      mode: "date",
      attrs: this.attrs,
      focus: false,
      yearpos: -1,
      placeholder: {
        datetime: "Select Date & Time",
        date: this.attrs.placeholder || "Select Date",
        time: "Select Time",
      },
      ...this.setModelDate(),
      days: ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"],
      months: Object.entries([
        "JAN",
        "FEB",
        "MAR",
        "APR",
        "MAY",
        "JUN",
        "JUL",
        "AUG",
        "SEP",
        "OCT",
        "NOV",
        "DEC",
      ]).map(([key, value]) => ({ key, value })),
    };
  }

  get yearlist() {
    let { year, yearpos } = this.state;
    let arr = [];
    arr.length = 12;
    arr.fill(+year);
    let pos = yearpos >= 0 ? yearpos : arr.length / 2;
    return arr
      .map((v, i) => {
        let index = i - pos;
        return v + index;
      })
      .chunk(4);
  }

  setMode(mode) {
    this.setState({ mode });
  }

  get yearmode() {
    return this.state.mode == "year";
  }

  get datemode() {
    return this.state.mode == "date";
  }

  get date() {
    let { year, month, dd, hh, mm, ss } = this.state;
    return new Date(year, month, dd, hh, mm, ss) || new Date();
  }

  get calender() {
    return this.date.calender();
  }

  get classes() {
    return {
      input:
        "max-w-full focus:ring focus:outline-none rounded w-full dark:placeholder-gray-400 border-0",
      middle: "h-10 flex justify-center items-center",
      button: "btn add-btn",
    };
  }

  handleOutCLick(e) {
    let flag = mixins.checkParent(
      e.target,
      (p, e) => e == this.appinput.current
    );
    if (this.state.focus) {
      // console.log("clikecout", e.target, this.appinput.current, flag);
    }
    if (!flag) {
      this.setState({
        focus: false,
      });
    }
  }

  componentDidMount() {
    window.ComponentAppDatePicker = this;
    this.outclickmounted = (e) => this.handleOutCLick(e);
    document.addEventListener("click", this.outclickmounted);
  }

  componentWillUnmount() {
    this.outclickmounted &&
      document.removeEventListener("click", this.outclickmounted);
  }

  setModelDate(
    value = this?.props?.value || this?.props?.defaultValue,
    set = false
  ) {
    let self = this;
    let dateobj = value ? new Date(value) : new Date();
    dateobj = dateobj.toString() == "Invalid Date" ? new Date() : dateobj;
    let datearr = dateobj.toDateTime()?.split(" ") || [];
    let [
      year = new Date().getFullYear(),
      month = new Date().getMonth() + 1,
      dd = new Date().getDate(),
    ] = datearr[0]?.split("-") || [];
    let [hh = 0, mm = 0, ss = 0] = datearr[1]?.split(":") || [];
    month = month - 1;
    let obj = { year, month, dd, hh, mm, ss };
    if (set) {
      Object.entries(obj).map(([k, v]) => {
        self[k] = +v;
      });
    }
    return obj;
  }

  get attrs() {
    let { props } = this;
    return Object.except(props, [
      "className",
      "style",
      "labelClass",
      "controlClass",
      "labelpos",
      "children",
      "type",
    ]);
  }

  get yearOffset() {
    return this.yearmode ? 12 : 1;
  }

  handelTimeInput(e, name) {
    this.setState({
      [name]: e.target.value,
    });
  }

  setTime() {
    let now = new Date();
    let timestamp = now.toDateTime().split(" ");
    let [yy, mm, dd] = timestamp[0].split("-");
    let [hh, mi, ss] = timestamp[1].split(":");
    yy = yy.parse();
    (mm = mm - 1),
      (dd = dd.parse()),
      (hh = hh.parse()),
      (mi = mi.parse()),
      (ss = ss.parse() + this.ahead);
    this.setDate(new Date(yy, mm, dd, hh, mi, ss));
  }

  setDate(d, set = true) {
    let { time } = this.props;
    let { attrs } = this.state;
    this.setModelDate(d.toDateTime(), set);
    let value = time ? d.toDateTime() : d.toDate();
    this.setState({
      focus: false,
      attrs: {
        ...attrs,
        value,
      },
    });
    this.props.onChange && this.props.onChange(new Date(value), value);
  }

  selectMonth(e) {
    this.setState({
      month: Number(e.target.value),
    });
  }

  setYear(e, offset) {
    let { year } = this.state;
    this.setState({ year: year - offset });
  }

  setYearValue(year, yearpos) {
    this.setState({
      ...this.state,
      year,
      yearpos,
    });
    setTimeout(() => {
      this.setState({ mode: "date" });
    }, 100);
  }

  render() {
    //let appinput = React.createRef();
    let {
      calender,
      date,
      props,
      state,
      classes,
      datemode,
      yearmode,
      yearlist,
      yearOffset,
      appinput,
    } = this;
    let { className, disabledDates = () => false, type } = props;
    let {
      focus,
      hh,
      mm,
      ss,
      year,
      months,
      attrs,
      month,
      days,
      placeholder,
      dd,
    } = state;
    let time = ["time", "datetime"].includes(type);
    let mapfun = { time: "toTime", date: "toDate", datetime: "toDateTime" };
    attrs.value =
      attrs.value || attrs.defaultValue
        ? new Date(attrs.value || attrs.defaultValue)[mapfun[type]]()
        : "";
    return (
      <div
        ref={appinput}
        className={"AppDateTimePicker relative " + className}
        tabIndex={0}
      >
        <input
          className={classes.input}
          type="text"
          {...Object.except(attrs, ["defaultValue"])}
          placeholder={placeholder[type]}
          readOnly
          onFocus={() => this.setState({ focus: true })}
        />
        <i className="fa fa-calendar calender_icon"></i>
        <>
          {focus && (
            <div
              className="calender absolute top-full left-0 max-w-fit min-w-full p-4 z-10 border"
              style={{ left: "-10%" }}
            >
              <div className="flex">
                <div
                  className={
                    classes.middle + " w-10 cursor-pointer color-red text-2xl"
                  }
                  onClick={(e) => this.setYear(e, yearOffset)}
                >
                  {" "}
                  {"<"}{" "}
                </div>
                <div className={classes.middle + " w-full text-center flex"}>
                  <span
                    className="mx-2 cursor-pointer"
                    onClick={() => this.setMode("year")}
                  >
                    {year}
                  </span>
                  <select
                    value={month}
                    className="rounded mx-2 cursor-pointer p-2"
                    onChange={(e) => this.selectMonth(e)}
                  >
                    {months &&
                      months.map((m) => (
                        <option key={m.key} value={m.key}>
                          {m.value}
                        </option>
                      ))}
                  </select>
                </div>
                <div
                  className={
                    classes.middle + " w-10 cursor-pointer color-red text-2xl"
                  }
                  onClick={(e) => this.setYear(e, -yearOffset)}
                >
                  {">"}
                </div>
              </div>
              {datemode && (
                <div className="mt-4">
                  <div className="flex divide-x divide-slate-200">
                    {days &&
                      days.map((day, i) => (
                        <div
                          key={i}
                          className="w-10 h-10 flex justify-center items-center font-bold first:ml-auto last:mr-auto"
                        >
                          {day}
                        </div>
                      ))}
                  </div>
                  {calender &&
                    calender.map((row, i) => (
                      <div key={i} className="flex">
                        {row &&
                          row.map((d, j) => (
                            <div
                              key={j}
                              className={Object.className({
                                "w-10 h-10 flex justify-center items-center first:ml-auto last:mr-auto": true,
                                "cursor-pointer font-bold border":
                                  d.getMonth() == date.getMonth(),
                                "opacity-80": d.getMonth() != date.getMonth(),
                                "border rounded-full":
                                  d.toDate() == new Date().toDate(),
                                rounded:
                                  d.toDate() != new Date().toDate() &&
                                  d.getMonth() == date.getMonth(),
                                "bg-primary text-white ":
                                  d.toDate() == new Date(attrs?.value).toDate(),
                                "opacity-25": disabledDates(d),
                              })}
                              onClick={() =>
                                !disabledDates(d) &&
                                this.setDate(
                                  (d.toDate() + " " + date.toTime()).date()
                                )
                              }
                            >
                              <span>{d.getDate()}</span>
                            </div>
                          ))}
                      </div>
                    ))}
                </div>
              )}
              {yearmode && (
                <div className="mt-4 flex flex-col">
                  {yearlist.map((row, i) => (
                    <div
                      className="flex gap-2 [&:not(:last-child)]:[&:not(:first-child)]:my-1 mx-auto"
                      key={i}
                    >
                      {row.map((yval, j) => (
                        <div
                          key={j}
                          className={Object.className({
                            "p-2 cursor-pointer font-bold border": true,
                            rounded: yval != new Date().getFullYear(),
                            "border rounded-full":
                              yval == new Date().getFullYear(),
                            "bg-primary text-white": yval == year,
                            "opacity-25": disabledDates(
                              new Date(yval, month, dd, hh, mm, ss)
                            ),
                          })}
                          onClick={(e) =>
                            !disabledDates(
                              new Date(yval, month, dd, hh, mm, ss)
                            ) && this.setYearValue(yval, i * 4 + j)
                          }
                        >
                          {yval}
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              )}
              {time && (
                <>
                  <div className="mt-2">
                    <label className="font-bold">Time</label>
                    <div className="flex w-full">
                      <input
                        name="hour"
                        value={hh}
                        className={classes.input + " w-20 mx-auto"}
                        type="number"
                        min="0"
                        max="23"
                        onInput={(e) => this.handelTimeInput(e, "hh")}
                      />
                      <input
                        name="min"
                        value={mm}
                        className={classes.input + " w-20 mx-auto"}
                        type="number"
                        min="0"
                        max="59"
                        onInput={(e) => this.handelTimeInput(e, "mm")}
                      />
                      <input
                        name="sec"
                        value={ss}
                        className={classes.input + " w-20 mx-auto"}
                        type="number"
                        min="0"
                        max="59"
                        onInput={(e) => this.handelTimeInput(e, "ss")}
                      />
                    </div>
                  </div>
                  <div className="flex w-full mt-2">
                    <button
                      className={classes.button + " mx-auto"}
                      onClick={() => this.setTime()}
                      type="button"
                    >
                      NOW
                    </button>
                    <button
                      className={classes.button + " mx-auto"}
                      onClick={() => this.setDate(date)}
                      type="button"
                    >
                      Select
                    </button>
                  </div>
                </>
              )}
            </div>
          )}
        </>
      </div>
    );
  }
}

export default AppDatePicker;
