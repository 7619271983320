import React, { Component } from "react";
import mixinsFunc from "../mixins";
import { Link } from "react-router-dom";
import MenuModal from "./MenuModal";
import MenuPdfModal from "./MenuPdfModal";

export class MenuManagement extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("MenuManagement");
    this.mixins = mixinsFunc(this);
    this.state = {
      menuData: [],
      rowcount: 0,
      search: {
        limit: 10,
        page: 1,
        order_by: "updatedAt",
        order_direction: "DESC",
        location: "BiryaniHub",
      },
      subCategoryOptions: [],
      editData: {},
      viewMode: false,
      editMode: false,
      popupfields: {},
      popupdata: {},
      //
      menuPdfEditData: {},
      menuPdfViewMode: false,
      menuPdfEditMode: false,
      menuPdfPopupfields: {},
      menuPdfPopupdata: {},
    };
    this.columns = [
      {
        title: "Category",
        dataIndex: "item_category",
      },
      {
        title: "Sub Category",
        dataIndex: "item_sub_category",
      },
      {
        title: "Name",
        dataIndex: "item_name",
      },
      {
        title: "Priority",
        dataIndex: "item_order",
      },
      {
        title: "Item Description",
        dataIndex: "item_description",
        render: (text, record) => {
          return (
            <span
              className="text_truncate cursor-pointer"
              title={record?.item_description}
              onClick={() => alert(record?.item_description)}
            >
              {record?.item_description}
            </span>
          );
        },
      },
      {
        title: "Price",
        dataIndex: "item_retail_price",
        render: (text, record) => {
          return <>${text}</>;
        },
      },
    ];
    this.modalFields = [
      {
        type: "select",
        name: "item_category",
        label: "Category",
        options: Object.keys(menucategories).options(),
        // onChange: (value) => {
        //   let subcategory = this.modalFields.find(
        //     (o) => o.name == "item_sub_category"
        //   );
        //   console.log(value[0].key, subcategory);
        //   if (menucategories[value[0].key].length > 0) {
        //     subcategory.options = Object.assign(
        //       subcategory.options,
        //       menucategories[value[0].key].options()
        //     );
        //   } else {
        //     subcategory.options = [];
        //   }
        // },
        onChange: (v) => this.handleCategoryChange(v),
        required: true,
      },
      {
        type: "select",
        name: "item_sub_category",
        label: "Sub Category",
        id: "subcategory-input",
        options: [],
        required: true,
      },
      {
        type: "text",
        name: "item_name",
        label: "Name",
        required: true,
      },
      {
        type: "number",
        name: "item_retail_price",
        label: "Price",
        step: "0.01",
        min: "0",
        max: "100",
        onInput: (e) => this.decimalValidate(e),
        required: true,
      },
      {
        type: "number",
        name: "item_order",
        label: "Priority",
        required: true,
      },
      {
        type: "file",
        foldername: "biryanihub",
        fileType: "item_image",
        name: "attachment_id",
        label: "Upload Image",
        accept: "image/*",
        required: true,
      },
      {
        type: "textarea",
        name: "item_description",
        label: "Enter Description",
      },
    ];
    this.pdfMenuModalFields = [
      {
        type: "text",
        name: "menu_name",
        label: "Name",
        required: true,
      },
      {
        type: "file",
        label: "Upload Menu",
        foldername: "biryanihub",
        fileType: "menu_file",
        name: "attachment_id",
        accept: "application/pdf",
        required: true,
      },
    ];
    this.menuModalRef = React.createRef();
    this.pdfMenuModalRef = React.createRef();
  }

  componentDidMount() {
    this.fetchList(this.state.search.location);
  }

  handleCategoryChange([val]) {
    // console.log({ val });
    let options = menucategories[val.key].options();
    // console.log({ options });
    if (val) {
      this.setState((state) => ({
        ...state,
        subCategoryOptions: [],
      }));
      let subCatInput = document.querySelector(
        "[name='appinput-item_sub_category'].form-group"
      );
      let { values: subCatOptions = [] } = subCatInput.vnode.refs.select.state;
      if (subCatOptions.length) {
        subCatInput.vnode.refs.select.clearAll();
      }
      this.setState({
        subCategoryOptions: options,
      });
    }
  }

  decimalValidate(e) {
    const inputValue = e.target.value;
    if (!/^\d+(\.\d{0,2})?$/.test(inputValue)) {
      e.target.value = inputValue.slice(0, -1);
    }
  }

  fetchList(value) {
    let payload = {
      location: value,
    };
    this.props.api
      .apiGetMenuItems(this.state.search, payload)
      .then(({ data, total }) => {
        console.log({ apiGetMenuItems: data });
        this.setState({
          menuData: data,
          rowcount: total,
        });
      });
  }

  addModalFields() {
    let fields = (this.modalFields || []).reduce(
      (o, n) => ({ ...o, [n.name]: n }),
      {}
    );
    let formdata = Object.map(fields, (v, k) => "");
    // console.log("formdata: ", fields, formdata);
    this.setState({
      popupfields: fields,
      popupdata: formdata,
    });
  }

  editAction(v) {
    console.log("edit: ", v);
    this.openMenuModal();
    this.setState({
      popupdata: v,
      editMode: true,
      viewMode: false,
      editData: v,
    });
    // update sub category based on category
    let subcategory = this.modalFields.find(
      (o) => o.name == "item_sub_category"
    );
    subcategory.options = Object.assign(
      subcategory.options,
      menucategories[v.item_category].options()
    );
  }

  deleteAction(value) {
    console.log("delete action: ", value);
    let payload = {
      item_name: value.item_name,
      location: this.state.search.location,
    };

    confirm(`Are you sure want to delete this item ?`).promise.then(() => {
      this.props.api.apiDeleteMenuItem(payload).then(() => {
        this.fetchList(this.state.search.location);
      });
    });
  }

  openMenuModal() {
    this.menuModalRef.current.click();
  }

  closeModal() {
    document.querySelector("#add_menu_modal #bsmodalclose").click();
  }
  // ----------------------------------------------------------------

  addPdfModalFields() {
    let fields = (this.pdfMenuModalFields || []).reduce(
      (o, n) => ({ ...o, [n.name]: n }),
      {}
    );
    let formdata = Object.map(fields, (v, k) => "");
    // console.log("formdata: ", fields, formdata);
    this.setState({
      menuPdfPopupfields: fields,
      menuPdfPopupdata: formdata,
    });
  }

  openMenuPdfModal() {
    this.menuPdfPopupdata.current.click();
  }

  closeMenuPdfModal() {
    document.querySelector("#add_pdf_menu_modal #bsmodalclose").click();
  }

  handleMenuPdfSubmit(event, data, form) {
    event.preventDefault();
    // console.log("formData", data, form);

    const resolveList = () => {
      success(
        `${
          this.state.editMode
            ? "Item Updated successfully"
            : "Item added successfully"
        }`
      );
      form.reset();
      this.closeMenuPdfModal();
      this.fetchList(this.state.search.location);
    };
    let payload = {
      ...data,
    };
    console.log({ payload });
    this.props.api.apiAddMenuFile(payload).then(resolveList);
  }

  // ----------------------------------------------------------------
  handleLocationChange(v) {
    console.log({ handleLocationChange: v[0].label });
    this.setState({
      search: {
        location: v[0].label,
      },
    });
    this.fetchList(v[0].label);
  }

  handleSubmit(event, data, form) {
    event.preventDefault();
    console.log("formData", data);

    const resolveList = () => {
      success(
        `${
          this.state.editMode
            ? "Item Updated successfully"
            : "Item added successfully"
        }`
      );
      form.reset();
      this.closeModal();
      this.fetchList(this.state.search.location);
    };
    if (this.state.editMode) {
      let editPayload = {
        ...Object.except(data, ["imageUrl", "fileName"]),
        item_image: data?.imageUrl || data?.item_image,
        item_retail_price: Number(data.item_retail_price),
        item_order: Number(data.item_order),
        location: this.state.search.location,
      };
      // console.log({ editPayload });
      this.props.api.apiUpdateMenuItem(editPayload).then(resolveList);
    } else {
      let addPayload = {
        ...Object.except(data, ["imageUrl", "fileName"]),
        item_image: data?.imageUrl,
        item_retail_price: Number(data.item_retail_price),
        item_order: Number(data.item_order),
        location: this.state.search.location,
      };
      // console.log({ addPayload });
      this.props.api.apiAddMenuItem(addPayload).then(resolveList);
    }
  }

  onPageChange(pageData) {
    this.setState(
      {
        search: {
          ...this.state.search,
          page: pageData.current,
          limit: pageData.pageSize || pageData.defaultPageSize || 25,
        },
      },
      (state) => this.fetchList(this.state.search.location)
    );
  }
  updateFields(fields) {
    fields.find((o) => o.name === "item_sub_category").options =
      this.state.subCategoryOptions;

    return fields;
  }

  render() {
    let {
      menuData,
      rowcount,
      editData,
      popupdata,
      popupfields,
      editMode,
      viewMode,
      //
      menuPdfEditData,
      menuPdfViewMode,
      menuPdfEditMode,
      menuPdfPopupfields,
      menuPdfPopupdata,
    } = this.state;
    let { columns = [], modalFields } = this;
    modalFields = this.updateFields(modalFields);
    return (
      <AppWrapper title="Menu Management">
        <div className="row mb-3">
          <div className="col-12 col-md-6">
            <AppInput
              type="select"
              name="location"
              placeholder="Select Location"
              options={_enum.LOCATIONS}
              onChange={(...args) => this.handleLocationChange(...args)}
              defaultValue={this.state.search.location}
              readOnly={true}
              className="w-50 mt-3 top_location_select"
            ></AppInput>
          </div>
          <div className="col-12 col-md-6 d-flex align-items-center justify-content-md-end">
            <Link
              className="btn modal_btn lg bordered_variant me-3"
              ref={this.pdfMenuModalRef}
              data-bs-toggle="modal"
              data-bs-target="#add_pdf_menu_modal"
              onClick={(...args) => this.addPdfModalFields(...args)}
            >
              <i className="fa fa-upload me-2"></i>
              Add Menu
            </Link>
            <Link
              className="btn modal_btn lg"
              ref={this.menuModalRef}
              data-bs-toggle="modal"
              data-bs-target="#add_menu_modal"
              onClick={(...args) => this.addModalFields(...args)}
            >
              <i className="fa fa-plus me-2"></i>
              Add Item
            </Link>
          </div>
        </div>
        <AppTable
          data={menuData}
          columns={columns}
          reorder={true}
          total={rowcount}
          pageSize={10}
          // showPagination={false}
          onChange={(...arg) => this.onPageChange(...arg)}
          editAction={(data) => this.editAction(data)}
          deleteAction={(data) => this.deleteAction(data)}
        ></AppTable>
        <MenuModal
          id="add_menu_modal"
          title={`${editMode ? "Update Item" : "Add Item"}`}
          submitText={`${editMode ? "Update" : "Add"}`}
          editdata={editData}
          data={popupdata}
          fields={popupfields}
          // disableallfields={editMode ? true : false}
          disablefields={[
            "item_category",
            "item_sub_category",
            "item_description",
          ]}
          readOnlyfields={["item_name"]}
          onSubmit={(...args) => this.handleSubmit(...args)}
          onClose={() =>
            this.setState({
              popupdata: {},
              editData: {},
              editMode: false,
              viewMode: false,
            })
          }
        />
        <MenuPdfModal
          id="add_pdf_menu_modal"
          title={`${menuPdfEditMode ? "Update Menu" : "Add Menu"}`}
          submitText={`${menuPdfEditMode ? "Update" : "Add"}`}
          editdata={menuPdfEditData}
          data={menuPdfPopupdata}
          fields={menuPdfPopupfields}
          onSubmit={(...args) => this.handleMenuPdfSubmit(...args)}
          onClose={() =>
            this.setState({
              menuPdfPopupdata: {},
              menuPdfEditData: {},
              menuPdfEditMode: false,
              menuPdfViewMode: false,
            })
          }
        />
      </AppWrapper>
    );
  }
}

export default connect(MenuManagement);
