/* global imagepaths connect React Component */
import React, { useEffect, useState } from "react";
import { Navigation } from "react-minimal-side-navigation";
import "react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css";
import { useLocation } from "react-router-dom";
import adminMenu from "./Menulist/AdminMenu";
import locationManagerMenu from "./Menulist/LocationManagerMenu";

const Sidebar = (props) => {
  const location = useLocation();
  const [sidemenuData, setSidemenuData] = useState([]);
  let pathname = location.pathname;

  // useEffect(() => {
  //   console.log("Location changed", location.pathname);
  // }, [location]);

  useEffect(() => {
    const role = props?.store?.user?.role;
    console.log({ role });
    if (role === "ADMIN") {
      setSidemenuData(adminMenu);
    } else if (role === "LOCATION_MANAGER") {
      setSidemenuData(locationManagerMenu);
    }
  }, []);

  return (
    <div className="sidebar" id="sidebar">
      <div className="sidebar-inner slimscroll">
        <div id="sidebar-menu" className="sidebar-menu">
          <Navigation
            activeItemId={location.pathname}
            onSelect={({ itemId }) => {
              navigate(itemId);
            }}
            items={
              sidemenuData &&
              sidemenuData.map((obj, i) => {
                if (obj.subNav) {
                  return {
                    ...obj,
                    elemBefore: () => <i className={obj.icon + " fs-5"}></i>,
                    subNav: obj.subNav.map((o) => {
                      return {
                        ...o,
                        elemBefore: () => <i className={o.icon + " fs-5"}></i>,
                      };
                    }),
                  };
                }
                return {
                  ...obj,
                  elemBefore: () => <i className={obj.icon + " fs-5"}></i>,
                };
              })
            }
          />
        </div>
      </div>
    </div>
  );
};

export default connect(Sidebar);
