export default {
    // apiGetPartyOrdersMoke() {
    //     let data = [
    //         {
    //             name: "Chris",
    //             phone: "9988774455",
    //             address: "1 Main Street, New York NY 99999",
    //             event_date: "20/10/2023",
    //             event_time: "3:00 pm",
    //             week_day: "Thursday",
    //             status: "Open",
    //         }
    //     ];
    //     return this.mokeapi(() => (data), "Getting all data please wait...", 1000);
    // },

    apiAddPartyOrders(data) {
        let payload = {
            ...data,
            status: "OPEN",
        }
        return this.request(this.api().addPartyOrder, payload)
            // .urltransform(urldata)
            .exec().log()
            .mapresults((response) => {
                return response.data
            }).get();
    },

    apiGetPartyOrders(urldata = app.mixin.params()) {
        return this.request(this.api().getAllPartyOrder)
            .urltransform(urldata)
            .exec().log()
            .mapresults((response) => {
                return {
                    data: response.data[0].records,
                    total: response.data[0].pagination[0]?.totalRecords || 0
                }
            }).get();
    },
}