export class Dashboard extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("Dashboard");
    this.state = {
      dashboardData: [],
      accordianList: [],
    };
  }

  componentDidMount() {
    this.setState({
      dashboardData: [
        {
          content: "2",
          label: "Active Debts",
        },
        {
          content: "$9,400",
          label: "Repayment Amt.",
        },
        {
          content: "$600",
          label: "Min. Payments",
        },
        {
          content: "$300",
          label: "Payments Made",
        },
        {
          content: "6%",
          label: "Overall Progress",
        },
      ],
    });
  }
  render() {
    let { dashboardData } = this.state;
    return (
      <AppWrapper title="Dashboard">
        <div className="row row-cols-2 row-cols-sm-3 row-cols-md-4 row-cols-lg-5 row-cols-xl-5">
          {dashboardData?.map((item, k) => {
            return (
              <div className="col-12" key={k}>
                <div className="card dash-widget d-none">
                  <div className="card-body py-4">
                    <div className="dash-widget-info">
                      <h3>{item.content}</h3>
                      <span>{item.label}</span>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </AppWrapper>
    );
  }
}

export default connect(Dashboard);
