import React, { Component } from "react";
import { Link } from "react-router-dom";

export class PartyOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowcount: 0,
      partyOrderData: [],
      orderLists: [],
    };
    this.columns = [
      {
        title: "Name",
        dataIndex: "name",
      },
      {
        title: "Phone No.",
        dataIndex: "phone_number",
      },
      {
        title: "Address",
        dataIndex: "address",
      },
      {
        title: "Event Date",
        dataIndex: "date",
      },
      {
        title: "Event Time",
        dataIndex: "time",
      },
      {
        title: "Weekday",
        dataIndex: "weekday",
      },
      {
        title: "Status",
        dataIndex: "status",
      },
      {
        title: "Print Order",
        dataIndex: "order_id",
        render: (text, record) => {
          return (
            <>
              <button
                type="button"
                className="print_pdf_btn"
                onClick={(e) => this.handlePrintOrder(e, record)}
              >
                <i className="fa fa-print"></i>
              </button>
            </>
          );
        },
      },
    ];
  }

  componentDidMount() {
    this.props.api.apiGetPartyOrders().then(({ data, total }) => {
      // console.log({ apiGetPartyOrders: data });
      this.setState({
        partyOrderData: data,
        rowcount: total,
      });
    });
  }

  editAction() {}

  deleteAction() {}

  handlePrintOrder(e, record) {
    e.preventDefault();
    e.stopPropagation();

    let userData = {
      name: record?.name,
      phone_number: record?.phone_number,
      address: record?.address,
      date: record?.date,
      time: record?.time,
      weekday: record?.weekday,
      delivery_mode: record?.delivery_mode,
      items: [...record?.items],
      total: record?.total,
      discount_percent: record?.discount_percent,
      delivery_charge: record?.delivery_charge,
      grand_total: record?.grand_total,
    };
    let payload = {
      ...userData,
    };

    // console.log({ payload });

    this.props.api.apiGeneratePdf(payload).then((res) => {
      if (res?.status === 200) {
        // console.log({ res });
        let url = res?.data?.url;
        let filename = res?.data?.file_name;
        let atag = document.createElement("a");
        atag.href = url || "";
        atag.download = filename || `invoice-${new Date().toISOString()}`;
        document.body.appendChild(atag);
        atag.click();
        document.body.removeChild(atag);
      }
    });
  }

  render() {
    let { partyOrderData, rowcount } = this.state;
    let { columns = [] } = this;
    return (
      <AppWrapper title="Party Orders">
        <div className="row mb-3">
          <div className="col-12 text-end">
            <Link
              className="btn modal_btn lg"
              to="/admin/party_orders/configurations"
              // ref={this.menuModalRef}
              // data-bs-toggle="modal"
              // data-bs-target="#add_menu_modal"
              // onClick={(...args) => this.addModalFields(...args)}
            >
              <i className="fa fa-plus me-2"></i>
              Create New
            </Link>
          </div>
        </div>
        <AppTable
          data={partyOrderData}
          columns={columns}
          reorder={true}
          total={rowcount}
          pageSize={10}
          editAction={(data) => this.editAction(data)}
          deleteAction={(data) => this.deleteAction(data)}
        ></AppTable>
      </AppWrapper>
    );
  }
}

export default connect(PartyOrders);
